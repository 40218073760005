<template>
    <Suspense>
      <template #default>
        <NotFounds />
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
</template>

<script>
import NotFounds from "@/components/Pages/NotFounds.vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import { Suspense } from "vue";
export default {
  name: "Play",
  props: {
    id: String
  },
  components: {
    NotFounds,
    Suspense,
    Spinner
  },
};
</script>

<style>
</style>